import React from 'react'
import "./registerSuccess.css"
import Brad from '../Brad/Brad'

const registerSuccess = () => {
  return (
    <>
      <Brad head="Khamma Ghanni" desc="The Food Fest" />

      <div className="container mt-2 mb-2">
        <div className="container-001 mt-5">
          <div className="header-001 ">
            <div className='circle-001 m-auto'>&#10004; </div>
          </div>
          <div className="content-001 mt-5">
            <p className='mb-4 text-center fs-3'>Verify your Email Address</p>
            <p className='fs-6 text-center p-3'>We have sent a link to verify your email address in your inbox. Please check your mail and click on the link to verify and continue booking your pass/passes for Khamma Ghani - The Foodfest 2025</p>
          </div>
        </div>
      </div>

    </>
  )
}

export default registerSuccess;