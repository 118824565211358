import React from 'react'
import Brad from '../Brad/Brad';
import RegisterForm from './form.jsx';
import './form.css'
import FAQ from './faq.jsx';
import Banner from './banner.jsx';

function Register() {
  return (
    <>
        <Banner />
        <Brad head="Khamma Ghanni 2025" desc="The Food Fest" />
        <RegisterForm />
        <FAQ/> 
    </>
  )
}

export default Register;