import React from 'react'
import './banner.css'

function Banner() {
    return (
        <div className="unique-banner-container">
            <h2 className="unique-banner-heading">
                Buy a pass here and pick up a 50% off Nicco Park coupon at the event venue!
            </h2>
        </div>
    );
}

export default Banner;