import { React, useEffect, useState } from 'react';

function RegisterForm() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [RollNumber, setRollNumber] = useState("");
  const [Room, setRoom] = useState("");
  const [Email, setEmail] = useState("");
  const [Contact, setContact] = useState("");
  const [Number_of_coupons, setNumber_of_coupons] = useState("");
  const [Hall, setHall] = useState("");
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  function handlesubmit(event) {
    setLoading(true)
  }

  return (
    <>
      <div className='d-flex justify-content-center'>
        <div className="m-3 row-outer-khamma" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", maxWidth: "1200px", margin: "auto" }}>
          <div className="row-khamma">
            <div className='col-md-4 col-khamma-left' style={{ backgroundColor: "#f24415", color: "white", }}>
              <div style={{ width: "100%", height: "100%", justifyContent: "center", padding: "10px 0 10px 0" }} className='d-flex align-items-center w-100'>
                <h3 className='text-center fw-bold'>Book Your Passes Now!</h3>
              </div>
            </div>
            <div className='col-md-8 col-khamma-right' style={{ backgroundColor: "white" }}>
              <div className='d-flex justify-content-center mt-4'>
                <h3>User Detail</h3>
              </div>
              <form className="row g-3 form-khamma" onSubmit={handlesubmit} style={{ "--bs-gutter-x": "0", margin: 'auto' }} action="https://payment-rca.vercel.app/razorpay" method="POST">
                <div className="col-md-6 mt-4" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <input type="text" id="FirstName" name="FirstName" placeholder="First Name" className="form-control khamma-form-inputs " value={FirstName}
                    onChange={(e) => setFirstName(e.target.value)} required />
                </div>
                <div className="col-md-6 mt-4" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <input type="text" id="LastName" name="LastName" placeholder="Last Name" className="form-control khamma-form-inputs" value={LastName}
                    onChange={(e) => setLastName(e.target.value)} required />
                </div>
                {/* <div className="col-md-6 mt-4" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <input type="text" id="rollNumber" name="rollNumber" placeholder="Roll Number" className="form-control khamma-form-inputs" value={RollNumber}
                    onChange={(e) => setRollNumber(e.target.value)} required />
                </div> */}

                {/* <div className="col-md-6 mt-4" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <input type="text" id="room" name="room" placeholder="Room Number" className="form-control khamma-form-inputs" value={Room}
                    onChange={(e) => setRoom(e.target.value)} required />
                </div> */}
                <div className="col-md-6 mt-4" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <input type="email" id="email" name="email" placeholder="Email Address" className="form-control khamma-form-inputs" value={Email}
                    onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="col-md-6 mt-4" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <input type="text" id="contact" name="contact" placeholder="Phone Number" className="form-control khamma-form-inputs" value={Contact}
                    onChange={(e) => setContact(e.target.value)} required />
                </div>
                <div className="col-md-6 mt-4" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <input type="number" id="couponNumbers" name="number_of_coupons" placeholder="Number of Passes" required min="1" className="form-control khamma-form-inputs" value={Number_of_coupons}
                    onChange={(e) => { setNumber_of_coupons(e.target.value); setPrice(350 * e.target.value) }} />
                </div>
                <div className="col-md-6 mt-4" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <select name="hall" id="browser" className="form-select  khamma-form-inputs" placeholder="Hall" value={Hall}
                    onChange={(e) => setHall(e.target.value)} required>
                    <option disabled value="">Select Hall</option>
                    <option value="Not Applicable">Not Applicable/ Residential</option>
                    <option value="ABP Hall">ABP</option>
                    <option value="Azad">Azad</option>
                    <option value="BC Roy">BC Roy</option>
                    <option value="BRH">BRH</option>
                    <option value="Gokhale">Gokhale</option>
                    <option value="HJB">HJB</option>
                    <option value="JCB">JCB</option>
                    <option value="LBS">LBS</option>
                    <option value="LLR">LLR</option>
                    <option value="MMM">MMM</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="Nehru">Nehru</option>
                    <option value="Patel">Patel</option>
                    <option value="RK">RK</option>
                    <option value="RP">RP</option>
                    <option value="RLB">RLB</option>
                    <option value="SAM">SAM</option>
                    <option value="SBP">SBP</option>
                    <option value="SNIG">SNIG</option>
                    <option value="SNVH">SNVH</option>
                    <option value="VS">VS</option>
                    <option value="VSRC1">VSRC 1</option>
                    <option value="VSRC2">VSRC 2</option>
                    <option value="Zakir Hussain Hall">Zakir Hussain Hall</option>


                  </select>
                </div>
                <div className="col-12 mt-5 mb-5 d-flex justify-content-center ">
                  {loading ? <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div> : (
                    <button type="submit" className="khamma-button" style={{ backgroundColor: "#f24415", color: "white" }}>
                      Buy Passes
                      {Number_of_coupons > 0 ? (<span> for ₹{price}</span>) : null}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterForm